$(function() {  
    $(document).foundation();
    
    $(document).on('click', 'a', function(e){
        if($(this).attr('href') == '#'){
            e.preventDefault();
        }
    });

    function clean_last_empty_p($p){
        $p.remove();
        check_last_empty_ps();
    }

    function check_last_empty_ps(){
        var $p = $('#content p').last();
        var html = $.trim($p.html());
        if(html.indexOf('flex-video')){
            $p.find('br').last().remove();
            $p.find('br').first().remove();
        }
        if(!html || html == '&nbsp;'){
            clean_last_empty_p($p);
        }
        else{
            check_first_empty_ps();
        }
       
    }
    check_last_empty_ps();

    function clean_first_empty_p($p){
        $p.remove();
        check_first_empty_ps();
    }

    function check_first_empty_ps(){
        var $p = $('#content p').first();
        var html = $.trim($p.html());
        if(html.indexOf('flex-video')){
            $p.find('br').last().remove();
            $p.find('br').first().remove();
        }
        if(!html || html == '&nbsp;'){
            clean_first_empty_p($p);
        }
       
    }

    function correct_td_img(){
        if (window.matchMedia(Foundation.media_queries.medium).matches) {
            $('#content td img').each(function(){ 
                $(this).css({width:'auto',height:'auto'});
            });
        }
        else {
            $('#content td img').each(function(){
                var td_w = $('#content').width();
                console.log(td_w);
                $(this).css({width:td_w,height:'auto'});
            });
        }
        
       
    }
    correct_td_img();

    $(window).on('resize', function(){  

        correct_td_img(); 

    });
    
});

