$(function() { 
    $('iframe').each(function(){
        var more_classes = '';
        var src = $(this).prop('src');
        if(src.indexOf('vimeo')){
            more_classes += ' widescreen vimeo';
        }
        $(this).wrap('<div class="flex-video '+more_classes+'" />');

    });
    

});

