$(function() {  

    $('#main-menu > ul > li > ul > li').each(function(){
        var $first_a = $(this).find('> a').first();
        var $ul = $(this).find('> ul').first();
        if($ul.length){
            $ul.prepend('<li><a href="'+$first_a.attr('href')+'">'+$first_a.text()+'</a></li>');
            $first_a.prop('href', '#');

            $(this).on('click', function(){
                $(this).find('> ul').fadeIn();
            });
        }
        
    });

    $('#content, #bgimages, #meta-menu').on('click', function(e){

        if($('#main-menu > ul > li > ul > li > ul').is(':visible')){
            $('#main-menu > ul > li > ul > li > ul').fadeOut();
        }
        
       
    });

    
   
});

